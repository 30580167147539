import { useState } from "react";
import { Link } from "gatsby";
/** @jsx jsx */
import { css, jsx } from "@emotion/react"; // eslint-disable-line no-unused-vars
import tw from "tailwind.macro";

import Logo from "~images/brand/icon.svg";
import "hamburgers/dist/hamburgers.min.css";

const Links = () => (
  <div id="header-links">
    <Link
      className="trans-all text-light button button-wide"
      id="header-link-contact"
      to="/contact"
    >
      Contact
    </Link>
    <Link to="/work">Work</Link>
    <Link to="/services">Services</Link>
    <Link to="/about">About</Link>
  </div>
);

const Header = () => {
  const [isActive, setActive] = useState(false);

  return (
    <header id="header" className="font-sans">
      <div className="flex items-center justify-between h-24 py-6 px-16 mx-auto w-full">
        {/* Logo */}
        <Link className="h-full" to="/">
          <img className="h-full" src={Logo} alt="Icon"></img>
        </Link>

        {/* Navigation button */}
        <div className="md:hidden block z-10">
          <button
            onClick={() => setActive(!isActive)}
            className={`hamburger hamburger--stand ${
              isActive ? "is-active" : ""
            }`}
            css={css`
              padding: 5px 0 0 0;
            `}
            type="button"
          >
            <span className="hamburger-box">
              <span
                className="hamburger-inner"
                css={css`
                  &,
                  &::after,
                  &::before {
                    width: 30px;
                    height: 4px;
                    padding-bottom: 5px;
                    ${isActive
                      ? "background-color: #F2FAFC !important"
                      : tw`bg-blue-primary`};
                    transition: all 0.3s ease !important;
                  }
                `}
              ></span>
            </span>
          </button>
        </div>

        {/* Navigation - desktop */}
        <nav
          className="md:block hidden"
          css={css`
            a {
              ${tw`mx-3 text-lg font-bold`}
            }
          `}
        >
          <Links />
        </nav>

        {/* Navigation - mobile */}
        <nav
          css={css`
            height: 100%;
            position: fixed;
            z-index: 1;
            top: 0;
            left: 0;
            overflow-x: hidden;
            padding-top: 60px;
            transition: 0.5s;
            ${tw`bg-gray-900 text-light px-8`}
            ${tw`md:hidden flex items-center text-center justify-center`}

                        a {
              ${tw`block text-2xl font-bold py-4`}
            }
            #header-link-contact {
              margin-bottom: 20px;
            }
          `}
          style={isActive ? { width: "100%" } : { width: "0", left: "-70px" }}
        >
          <Links />
        </nav>
      </div>
    </header>
  );
};

export default Header;
