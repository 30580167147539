import React from "react";
import { FaFacebook, FaTwitter, FaLinkedinIn, FaGithub } from "react-icons/fa";

import Logo from "~images/brand/icon.svg";

const links = [
  {
    icon: <FaFacebook />,
    url: "https://www.facebook.com/PaonixLLC",
  },
  {
    icon: <FaTwitter />,
    url: "https://www.twitter.com/PaonixLLC",
  },
  {
    icon: <FaLinkedinIn />,
    url: "https://www.linkedin.com/company/paonix",
  },
  {
    icon: <FaGithub />,
    url: "https://github.com/paonix",
  },
];

const Footer = () => (
  <footer id="footer" className="py-24 px-16 bg-light">
    <div className="flex flex-wrap">
      <div className="flex-0 md:mr-10 md:mb-0 mb-8 mx-auto">
        <img alt="Logo" className="h-32 w-auto" src={Logo} />
      </div>
      <div className="md:w-1/2 md:text-left text-center md:mb-0 mb-8 w-full">
        <h2 className="text-4xl font-sans font-bold -mb-2">Paonix LLC</h2>
        <h4 className="text-lg mb-2">
          Propelling businesses through web design.
        </h4>
        <p className="opacity-50">
          Copyright &copy; 2018-{new Date().getFullYear()}. All rights reserved.
        </p>
      </div>
      <div
        className="flex-1 flex items-center md:justify-end justify-center
            text-3xl"
      >
        {links.map((link, i) => (
          <a
            href={link.url}
            key={i}
            className="trans-all mx-3 hover:text-blue-tertiary"
          >
            {link.icon}
          </a>
        ))}
      </div>
    </div>
  </footer>
);

export default Footer;
