/** @jsx jsx */
import { css, jsx } from "@emotion/react"; // eslint-disable-line no-unused-vars

const Title = props => (
  <div
    className="p-16 flex items-center"
    css={css`
      height: calc(50vh - 6rem);
    `}
  >
    <div className="flex flex-col md:w-1/2 w-full">
      <h1 className="font-sans font-bold md:text-6xl text-4xl leading-tight mb-4">
        {props.title}
      </h1>
      <p className="text-xl">{props.subtitle}</p>
    </div>
    <div className="md:block hidden w-1/2"></div>
  </div>
);

export default Title;
