/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";

import Header from "~components/Header";
import Footer from "~components/Footer";

const Layout = ({ children }) => {
  return (
    <div className="bg-light font-mono leading-loose h-screen">
      <Header />
      <main className="bg-light" id="main">
        {children}
      </main>
      <Footer />
    </div>
  );
};

export default Layout;
