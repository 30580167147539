/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

function SEO({ description, lang, meta, title }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          metadata: siteMetadata {
            title
            description
            author
            keywords
            url: siteUrl
          }
        }
      }
    `
  );

  const desc = description || site.metadata.description;
  const reviews = [
    {
      author: "Will Kueshner",
      datePublished: "2020-05-11",
      ratingValue: 5,
      description: `Working with him was like working with a close friend, albeit a close friend much, much smarter than me: he listened closely to my ideas, asked great questions, and had just the development and design skills needed to make them a reality.`,
    },
    {
      author: "Jarren Horrocks",
      datePublished: "2020-07",
      ratingValue: 5,
      description: `Paonix has done a fantastic job helping me develop my website. There are many average web-devs out there, but this team is top-tier.`,
    },
    {
      author: "Andrea Balarezo",
      datePublished: "2018-08-01",
      ratingValue: 5,
      description: `Akash with Paonix did a great job with the website for our non-profit Single Mothers United. He communicated clearly, delivered quickly and produced an excellent website! We are thankful for his work with SMU. Highly recommend.`,
    },
  ];

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      meta={[
        {
          name: `description`,
          content: desc,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: desc,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.metadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: desc,
        },
        {
          name: `keywords`,
          content: site.metadata.keywords.join(", "),
        },
        {
          name: `geo.placename`,
          content: site.metadata.title,
        },
        {
          name: `geo.region`,
          content: "VA",
        },
      ].concat(meta)}
    >
      <script type="application/ld+json">
        {`
        {
          "@context": "https://schema.org",
          "@type": "Organization",
          "@id": "${site.metadata.url}",
          "name": "${site.metadata.title}",
          "address": {
            "@type": "PostalAddress",
            "addressLocality": "Virginia",
            "addressRegion": "VA",
            "postalCode": "20151",
            "addressCountry": "US"
          },
          "url": "${site.metadata.url}",
          "telephone": "+15715100521",
          "aggregateRating": {
            "@type": "AggregateRating",
            "ratingValue": "5",
            "reviewCount": "${reviews.length}"
          },
          "review": [${reviews
            .map(
              review => `
            {
              "@type": "Review",
              "author": "${review.author}",
              "datePublished": "${review.datePublished}",
              "description": "${review.description}",
              "reviewRating": {
                "@type": "Rating",
                "bestRating": "5",
                "ratingValue": "${review.ratingValue}",
                "worstRating": "1"
              }
            }
          `
            )
            .join(",")}
          ]
        }
      `}
      </script>
    </Helmet>
  );
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
};

export default SEO;
