import React from "react";

const PreFooter = props => (
  <div className="trans-all py-24 px-16 bg-gray-800 text-light md:text-xl text-lg">
    <h2 className="font-sans md:text-4xl text-3xl font-bold leading-normal mb-6">
      {props.title}
    </h2>
    <div>{props.children}</div>
  </div>
);

export default PreFooter;
