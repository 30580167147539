import { useState } from "react";
/** @jsx jsx */
import { css, jsx } from "@emotion/react"; // eslint-disable-line no-unused-vars
import anime from "animejs";
import ScrollTrigger from "react-scroll-trigger";

const BlockSection = ({ children, invert }) => {
  const [s, setS] = useState(false);

  return (
    <section
      className={`flex py-16 ${invert ? "bg-blue-primary" : "bg-light"}`}
    >
      <div
        className="flex flex-col justify-start"
        css={css`
          width: 6vw;
          div {
            height: 50px;
          }
        `}
      >
        <div
          className={`ani ani-block-section ${
            invert ? "bg-light" : "bg-blue-primary"
          }`}
        ></div>
        <div className="bg-blue-tertiary"></div>
        <div className="bg-blue-secondary"></div>
      </div>
      <ScrollTrigger
        onEnter={() =>
          // ANIMATE: blocks
          s ||
          (anime({
            targets: ".ani.ani-block-section",
            delay: 200,
            height: ["0px", "100px"],
            easing: "spring(1, 80, 10, 0)",
          }) &&
            setS(true))
        }
        className="flex-1 px-8"
      >
        <div
          className={`md:w-2/3 h-full flex items-center w-full mx-auto ${
            invert ? "text-light" : "text-gray-900"
          }`}
          css={css`
            min-height: 250px;
          `}
        >
          <div>{children}</div>
        </div>
      </ScrollTrigger>
      <div
        className="flex flex-col justify-end"
        css={css`
          width: 6vw;
          div {
            height: 50px;
          }
        `}
      >
        <div className="bg-blue-secondary"></div>
        <div className="bg-blue-tertiary"></div>
        <div
          className={`ani ani-block-section ${
            invert ? "bg-light" : "bg-blue-primary"
          }`}
        ></div>
      </div>
    </section>
  );
};

export default BlockSection;
